/* ./src/index.css
@tailwind base;
@tailwind components;
@tailwind utilities; */
main {
  min-height: 80vh;
}

h3 {
  padding: 1rem 0;
}

.rating span {
  margin: 0.1rem;
}
.card {
  background-color: #efefef;
  border: 1px solid #efefef;
}
.nftC{
  min-height: 450px;
}
.card:hover{
  box-shadow: 0 9px 25px 0 rgb(0 0 0 / 15%);
}
.bg-primary {
  background-color: #2A2F35!important;
}
.btn-primary {
  color: #fff;
  background-color: #3082f2;
  border-color: #3082f2;
}
.btn-primary:hover {
  color: #0a3483;
  background-color: #efefef;
  border-color:#efefef ;
}
.btn-check:active+.btn-primary, .btn-check:checked+.btn-primary, .btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle {
  color: #0a3483;
  background-color: #efefef;
  border-color:#efefef ;
}
.dropdown-item:focus, .dropdown-item:hover {
  color: #fff;
  background-color: #3082f2;
}
.card a {
  color: #000!important;
  text-decoration: none!important;
}
.card a:hover {
  color: #0a3483!important;

}
.card .card-img-top {
  width: 100%;
  height: 230px;
}
.form-control:focus {

  border-color: #0a3483!important;
 
}
.btn-primary:disabled {
  color: #fff;
  background-color: #0a3483;
  border-color: #0a3483;
}
.btn-outline-primary {
  color: #0a3483;
  border-color: #0a3483;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #0a3483;
  border-color: #0a3483;
}
.btn-primary:focus {
  box-shadow: 0 0 5px #0a3483;
}
.btn-primary:focus {
  color: #fff;
  background-color: #0a3483;
  border-color: #0a3483;
  box-shadow: none;
}
.filterItem{
  display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 30px;
    align-items: center;
}
.filterItem i{
  font-size: 27px;
  color:#3082f2;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-link.active:focus, .nav-tabs .nav-link.active:hover, .nav-tabs .nav-link:hover {
  border-bottom: 4px solid #3082f2;
}
.nav-link {
  color: #3082f2;
}
.btn-check:active+.btn-primary:focus, .btn-check:checked+.btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus {
  box-shadow: 0 0 0 0.25rem #3082f280;
}
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.Collapsible{
  font-size: 18px;
  border-bottom: 1px solid #efefef;
  padding: 20px;
}
.navPanel li button{
  font-weight: 600;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #3082f2;
}
.nav-link:focus, .nav-link:hover {
  color: #3082f2;
}
.btnConn{
  padding: 1.2rem;
}
.navbar{
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-dark .navbar-toggler {
  border-color: transparent;
}
.padd button{
  padding:1.2rem;
}
.aligntitle{
  display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.list-group-flush>.list-group-item {
  background-color: transparent!important;
}
.screenD .card{
  background-color: transparent;
}
.d-none{
  display: none;
}